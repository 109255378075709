import { reducers as coreReducers } from 'fe-core';
import { combineReducers } from 'redux-immutable';

import { bulkAddReducer as bulkAdd } from 'features/addTeam/bulkAdd/slices';
import { reducer as biller } from 'features/biller';
import { reducer as bottomDrawer } from 'features/bottomDrawers/slice';
import { reducer as calculateTipOutsDrawer } from 'features/bottomDrawers/TipPoolDrawer/CalculateTipOutsDrawer/slice';
import { reducer as downloadMobileAppTile } from 'features/dashboard/DashboardView/DownloadMobileAppWidget/slice';
import { reducer as needsReviewWidget } from 'features/dashboard/DashboardView/NeedsReviewWidget/slices';
import { shiftsWidgetReducer as shiftsWidget } from 'features/dashboard/DashboardView/ShiftsWidget/slices';
import { dashboardReducer as dashboardV3 } from 'features/dashboard/slices';
import { documentsReducer as documents } from 'features/employeeOnboarding/documents/slices';
import { singleDocumentPacketReducer as singleDocumentPacket } from 'features/employeeOnboarding/packet/singleDocument/slices';
import { packetWorkflowReducer as packetWorkflow } from 'features/employeeOnboarding/packet/slices';
import { hirePacketConfigurationReducer as hirePacketConfiguration } from 'features/employeeOnboarding/slices';
import { reducer as enforcement } from 'features/enforcement/slice';
import { reducer as experimentsV2 } from 'features/experiments/slice';
import { reducer as help } from 'features/help/slice';
import { SLICE_NAME as BACKGROUND_CHECKS_SLICE_NAME } from 'features/hrPro/BackgroundChecks/constants';
import { reducer as backgroundChecksReducer } from 'features/hrPro/BackgroundChecks/slice';
import { reducer as hubTimeClock } from 'features/hubTimeClock/state';
import { reducer as managePlan } from 'features/managePlan/slice';
import { MESSAGING_V2_SLICE_NAME } from 'features/messengerv2/constants';
import messengerReducer from 'features/messengerv2/slice';
import { reducer as monetization } from 'features/monetization/slices';
import { reducer as quickStartGuide } from 'features/onboarding/QuickStartGuide/slice';
import { payrollReducer as payroll } from 'features/payroll/payrollSlices';
import { reducer as retrials } from 'features/retrials/slice';
import { reducer as scheduleBuilderView } from 'features/scheduleBuilder/ScheduleBuilderView/slice';
import { reducer as tipPoolingPolicies } from 'features/settings/TipManagerSettingView/slice';
import { reducer as tipSettings } from 'features/settings/TipSettingView/slice';
import { reducer as sidePanel } from 'features/sidePanels/slice';
import { reducer as cloverSignUp } from 'features/signUp/clover/slice';
import { reducer as signUp } from 'features/signUp/slice';
import { TASK_MANAGER_SLICE_NAME } from 'features/taskManager/constants';
import taskManagerReducer from 'features/taskManager/slice';
import { SLICE_NAME as TEAM_SLICE_NAME } from 'features/team/constants';
import teamReducer from 'features/team/slice';
import { bulkTerminateReducer as bulkTerminate } from 'features/teamView/bulkTerminate/slices';
import { healthReportReducer as healthReport } from 'features/timeClock/HealthAnswersReportView/slices';
import { healthQuestionsReducer as healthQuestions } from 'features/timeClock/HealthQuestionsView/slices';
import { reducer as dailyReview } from 'features/timesheets/TimesheetsPage/dailyReview/slice';
import { timecardModalReducer as timecardModal } from 'features/timesheets/TimesheetsPage/TimecardModal/slices';

import addTeam from './addTeam';
import applicantMessenger from './applicantMessenger';
import billing from './billing';
import dashboard from './dashboard';
// Module-specific reducers. We'll chunk these in dynamically once
// we begin code-splitting optimizations for different views.
import departments from './departments';
import drawers from './drawers';
import employeeView from './employeeView';
// Top-level reducers
import entities from './entities';
import fetching from './fetching';
import ftu from './ftu';
import hiring from './hiring';
import hrPro from './hrPro';
import messenger from './messenger';
import metering from './metering';
import modals from './modals';
import nav from './nav';
import notifications from './notifications';
import ptoPolicy from './ptoPolicy';
import reports from './reports';
import retrial from './retrial';
import roles from './roles';
import scheduleBuilder from './scheduleBuilder';
import segmentedOnboarding from './segmentedOnboarding';
import session from './session';
import settings from './settings';
import teamEmails from './teamEmails';
import teamView from './teamView';
import tiers from './tiers';
import timecard from './timecard';
import timeOff from './timeOff';
import timeOffsDashboard from './timeOffsDashboard';
import timesheets from './timesheets';
import userReferrals from './userReferrals';
import userWebTimeclock from './userWebTimeclock';

export default combineReducers({
  tipPoolingPolicies,
  tipSettings,
  dailyReview,
  employeeView,
  entities,
  modals,
  session,
  teamView,
  settings,
  hiring,
  reports,
  messenger,
  tiers,
  departments,
  segmentedOnboarding,
  timeOff,
  ptoPolicy,
  fetching,
  nav,
  notifications,
  ftu,
  roles,
  addTeam,
  timesheets,
  scheduleBuilder,
  applicantMessenger,
  drawers,
  teamEmails,
  userReferrals,
  billing,
  retrial,
  metering,
  dashboard,
  dashboardV3,
  hrPro,
  timeOffsDashboard,
  timecard,
  userWebTimeclock,
  payroll,
  packetWorkflow,
  singleDocumentPacket,
  bulkAdd,
  bulkTerminate,
  healthReport,
  healthQuestions,
  hirePacketConfiguration,
  documents,
  hubTimeClock,
  biller,
  timecardModal,
  needsReviewWidget,
  shiftsWidget,
  signUp,
  cloverSignUp,
  downloadMobileAppTile,
  scheduleBuilderView,
  ...coreReducers,
  experimentsV2,
  quickStartGuide,
  bottomDrawer,
  sidePanel,
  managePlan,
  enforcement,
  [TEAM_SLICE_NAME]: teamReducer,
  [MESSAGING_V2_SLICE_NAME]: messengerReducer,
  [TASK_MANAGER_SLICE_NAME]: taskManagerReducer,
  help,
  monetization,
  retrials,
  calculateTipOutsDrawer,
  [BACKGROUND_CHECKS_SLICE_NAME]: backgroundChecksReducer,
});
