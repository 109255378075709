import './OverlayModal.scss';

import React from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import { createOneTimeEvent } from 'actions/session';

import {
  buildPayrollSalesTeamCalendlyUrl,
  calendlyUrlBuilderCommand,
} from 'features/calendlyEnterprise';

type CalendlyEnterpriseModalProps = {
  url: string;
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: Immutable.Map<string, string>;
  handleCloseModal: () => void;
  isImplementationDashboard?: boolean;
  onCreateOneTimeEvent?: (event: string, company: string) => void;
};

export const CalendlyEnterpriseModal = ({
  url,
  open,
  currentUser,
  handleCloseModal,
  onCreateOneTimeEvent,
  isImplementationDashboard = false,
}: CalendlyEnterpriseModalProps) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      if (isImplementationDashboard) {
        onCreateOneTimeEvent?.('schedule_call_finish', 'Company');
      }
    },
  });

  const root = document.getElementById('react-app-root') as HTMLElement;

  return (
    <PopupModal
      url={buildPayrollSalesTeamCalendlyUrl(
        calendlyUrlBuilderCommand({ url, currentUser })
      )}
      open={open}
      rootElement={root}
      onModalClose={handleCloseModal}
      data-testid="calendly-enterprise-modal"
    />
  );
};

export default connect(null, {
  onCreateOneTimeEvent: createOneTimeEvent,
})(CalendlyEnterpriseModal);
