import './Modal.scss';

import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import ModalCloseIcon from 'components/modals/ModalCloseIcon';

import cxHelpers from 'util/className';

import withCurrentRoute from '../../hocs/withCurrentRoute';

const DEFAULT_CONTENT_LABEL = 'Modal';

@withCurrentRoute
@cxHelpers('Modal')
export default class Modal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    large: PropTypes.bool,
    circle: PropTypes.bool,
    rounded: PropTypes.bool,
    roundedSmall: PropTypes.bool,
    stretched: PropTypes.bool,
    auto: PropTypes.bool,
    noPadding: PropTypes.bool,
    modalType: PropTypes.string,
    forceOpen: PropTypes.bool,
    closeIconInsideMore: PropTypes.bool,
    fullWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullScreenAtMobile: PropTypes.bool,
    overflowVisible: PropTypes.bool,
    hideCloseIcon: PropTypes.bool,
    children: PropTypes.node,
    closeButton: PropTypes.func,
    transparent: PropTypes.bool,
    overlayWindow: PropTypes.string,
    transparentOverlayWithShadow: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    newLayout: PropTypes.bool,
    closeButtonOutside: PropTypes.bool,
    onDismiss: PropTypes.func,
    borderRadius: PropTypes.number,
    currentRoute: PropTypes.string,
    closeOnRouteChange: PropTypes.bool,
    closeIcon: PropTypes.object,
    iconSize: PropTypes.string,
  };

  static defaultProps = {
    closeButton: () => false,
    shouldCloseOnOverlayClick: true,
  };

  componentDidUpdate(prevProps) {
    // Close the modal if route gets changed
    if (
      this.props.closeOnRouteChange &&
      this.props.currentRoute !== prevProps.currentRoute
    ) {
      this.props.onRequestClose();
    }
  }

  handleClose = e => {
    if (!this.props.forceOpen) {
      this.props.onRequestClose(e, true);
    }
  };

  handleDismiss = e => {
    if (this.props.onDismiss) this.props.onDismiss(e, true);

    this.handleClose(e, true);
  };

  getCloseButton = mobile =>
    this.props.closeButton(
      this.cxEl('close-icon', {
        outsideTop: mobile && this.props.newLayout,
        outsideRight: !mobile && this.props.newLayout,
      }),
      this.handleClose
    ) || (
      <ModalCloseIcon
        className={this.cxEl('close-icon', {
          outsideTop: mobile && this.props.newLayout,
          outsideRight: !mobile && this.props.newLayout,
          closeIconInsideMore: this.props.closeIconInsideMore,
        })}
        onClick={this.handleDismiss}
        iconColor={this.props.newLayout ? 'mono0' : null}
        closeIcon={this.props.closeIcon}
        iconSize={this.props.iconSize || 'medium'}
      />
    );

  render() {
    const {
      fullWidth,
      fullScreen,
      fullScreenAtMobile,
      isOpen,
      large,
      circle,
      rounded,
      roundedSmall,
      stretched,
      auto,
      noPadding,
      modalType,
      forceOpen,
      children,
      overflowVisible,
      hideCloseIcon,
      transparent,
      overlayWindow,
      transparentOverlayWithShadow,
      shouldCloseOnOverlayClick,
      newLayout,
      borderRadius,
    } = this.props;

    return (
      <MediaQuery maxWidth={600}>
        {mobile => {
          // Masks Rails sidebar. Can be removed when UI is fully decoupled.
          const style = {
            overlay: { zIndex: 10000 },
            content:
              newLayout && mobile
                ? {
                    width: '100%',
                    height: 'calc(100% - 96px)',
                    overflow: 'visible',
                  }
                : {
                    borderRadius,
                  },
            ...this.props.style,
          };

          return (
            <ReactModal
              className={this.cx({
                large,
                circle,
                rounded,
                roundedSmall,
                stretched,
                auto,
                fullWidth,
                fullScreen,
                fullScreenAtMobile,
                noPadding,
                overflowVisible,
                transparent,
                transparentOverlayWithShadow,
              })}
              overlayClassName={this.cxEl(overlayWindow || 'overlay')}
              style={style}
              isOpen={isOpen}
              contentLabel={modalType || DEFAULT_CONTENT_LABEL}
              onRequestClose={this.handleDismiss}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            >
              <Box h="100%" w="100%">
                {forceOpen || hideCloseIcon || this.getCloseButton(mobile)}
                {children}
              </Box>
            </ReactModal>
          );
        }}
      </MediaQuery>
    );
  }
}
