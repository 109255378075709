import { createAsyncThunk } from '@reduxjs/toolkit';
import windowLocation from 'fe-core/util/windowLocation';

import { ROUTES, SLICE_NAME } from 'features/signUp/constants';
import { createBusinessTracking } from 'features/signUp/util/apiHelper';

import { datadogLogInfo } from 'util/datadogLogs';
import { NAVIGATION } from 'util/navigation';
import { browserHistory } from 'util/router';
import { EVENT_ACTIONS } from 'util/tracking_constants';

import {
  createBusinessPayload,
  fetchEmployeeCheck,
  postCreateBusiness,
  postCreateEmployeeBusiness,
  postCreateJob,
  postPreSignupEnroll,
  putEmployeeUser,
} from './api';

const {
  EMPLOYEE_ALREADY_INVITED,
  EMPLOYEE_ASK_MANAGER,
  EMPLOYEE_ASK_MANAGER_WITH_FIND,
  EMPLOYEE_PHONE_SIGN_IN,
  EMPLOYEE_SUCCESS_STEP,
  QSG_SPLASH,
} = ROUTES;

export const createEmployeeBusiness = createAsyncThunk(
  `${SLICE_NAME}/createEmployeeBusiness`,
  (_, { rejectWithValue }) => {
    const payload = JSON.parse(
      window.localStorage.getItem('ob_company_details')
    );

    datadogLogInfo({
      message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
      context: {
        scenario: 'createEmployeeBusiness-before-postCreateEmployeeBusiness',
        ...payload,
      },
    });

    return postCreateEmployeeBusiness(payload)
      .then(params => {
        datadogLogInfo({
          message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
          context: {
            scenario: 'createEmployeeBusiness-createBusinessTracking',
            ...params,
          },
        });
        createBusinessTracking({ ...params });
        window.localStorage.removeItem('ob_company_details');
        return (window.location = params.redirectPath);
      })
      .catch(error => rejectWithValue(error.body || error.message));
  }
);

export const createBusiness = createAsyncThunk(
  `${SLICE_NAME}/createBusiness`,
  (
    {
      ownerInfo,
      ownerMotivation,
      businessInfo,
      tryOptions,
      accountDetails,
      experiments,
      history,
    },
    { rejectWithValue }
  ) => {
    const { payload, slimmedPayload } = createBusinessPayload({
      ownerInfo,
      ownerMotivation,
      businessInfo,
      tryOptions,
      accountDetails,
      experiments,
    });

    datadogLogInfo({
      message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
      context: {
        scenario: 'createBusiness-before-postCreateBusiness',
        ...payload,
      },
    });

    return postCreateBusiness(payload)
      .then(params => {
        window.sessionStorage.removeItem('campaign_name');
        window.localStorage.removeItem('ob_company_details');
        datadogLogInfo({
          message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
          context: {
            scenario: 'createBusiness-createBusinessTracking',
            ...params,
          },
        });

        createBusinessTracking({ ...params });

        if (params.redirectPath) {
          return (window.location = params.redirectPath);
        }

        return history.push(QSG_SPLASH);
      })
      .catch(error => {
        if (
          error?.body?.user?.includes('employee') ||
          error?.body?.user?.includes('no job')
        ) {
          window.localStorage.setItem(
            'ob_company_details',
            JSON.stringify(slimmedPayload)
          );
        }
        return rejectWithValue(error.body || error.message);
      });
  }
);

export const employeeCheck = createAsyncThunk(
  `${SLICE_NAME}/employeeCheck`,
  ({ email, phone }, { rejectWithValue }) =>
    fetchEmployeeCheck({ email, phone })
      .then(response => {
        switch (response.flow_type) {
          case 'email':
            return browserHistory.replace(EMPLOYEE_ASK_MANAGER);
          case 'email_invite':
            return browserHistory.replace(EMPLOYEE_ALREADY_INVITED);
          case 'phone':
            return browserHistory.replace(`${EMPLOYEE_PHONE_SIGN_IN}/${phone}`);
          case 'sign_in':
            windowLocation().href = NAVIGATION.ACCOUNT_SIGN_IN;
            return;
          case 'not_found':
            return browserHistory.replace(EMPLOYEE_ASK_MANAGER_WITH_FIND);
          default:
            throw new Error('Invalid response from employee check');
        }
      })
      .catch(error => rejectWithValue(error.body || error.message))
);

export const employeeCreate = createAsyncThunk(
  `${SLICE_NAME}/employeeCreate`,
  ({ user, locationId }, { rejectWithValue }) =>
    putEmployeeUser({ user })
      .then(() => postCreateJob({ locationId }))
      .then(params => {
        window.localStorage.setItem('job_id', params.job_id);
        return browserHistory.replace(EMPLOYEE_SUCCESS_STEP);
      })
      .catch(error => rejectWithValue(error.body || error.message))
);

export const preSignupEnrollInExperiment = createAsyncThunk(
  `/pre_signup_enroll`,
  (payload, { rejectWithValue }) =>
    postPreSignupEnroll(payload)
      .then(response => response.experiment)
      .catch(error => rejectWithValue(error.body || error.message))
);
