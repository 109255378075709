import { isObject } from 'lodash';

import { BILLING_CYCLES } from 'features/biller/constants';

export type CommandBarCallbackAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _args: Record<any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _context: Record<any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: Record<any, any>
) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getProps = (meta?: Record<any, any> | null) => {
  const props = meta?.source?.metadata;
  return props ? { ...props } : {};
};

const isInvalidTierName = (tierName?: string | null) =>
  tierName && !['basic', 'essentials', 'plus', 'enterprise'].includes(tierName);

export const openCheckoutModalCbAction = ({
  onChangeToNewTier,
  setModalPromoCode,
}: {
  onChangeToNewTier: ({
    tierName,
    workflowSource,
    billingFrequency,
  }: {
    tierName: string;
    workflowSource: string;
    billingFrequency: string;
  }) => void;
  setModalPromoCode: (promoCode: string) => void;
}) =>
  ((_args, _context, meta) => {
    const props = getProps(meta);
    const { tierName, promoCode } = props;
    const lowerTierName = tierName?.toLowerCase();

    if (isInvalidTierName(lowerTierName)) {
      const res = isObject(props) ? JSON.stringify(props) : props;
      throw new Error(
        `CB: openCheckoutModalCbAction: Invalid tier name: ${res}`
      );
    }

    if (promoCode) setModalPromoCode(promoCode);

    onChangeToNewTier({
      tierName: lowerTierName,
      workflowSource: 'command_bar',
      billingFrequency: BILLING_CYCLES.monthly,
    });
  }) as CommandBarCallbackAction;

export const showClickToCallDialogCbAction = ({
  showClickToCallDialog,
}: {
  showClickToCallDialog: ({ talkdeskId }: { talkdeskId?: string }) => void;
}) =>
  ((_args, _context, meta) => {
    const props = getProps(meta);
    const { talkdeskId } = props;
    showClickToCallDialog({ talkdeskId });
  }) as CommandBarCallbackAction;

export const startSetupPostSignUpCbAction = ({
  startSetupPostSignUp,
}: {
  startSetupPostSignUp: () => void;
}) =>
  (() => {
    startSetupPostSignUp();
  }) as CommandBarCallbackAction;

export const signOutFromDsuCbAction = ({
  signOutFromDsu,
}: {
  signOutFromDsu: () => void;
}) =>
  (() => {
    signOutFromDsu();
  }) as CommandBarCallbackAction;

export const startDsuSessionCbAction = ({
  startDsuSession,
}: {
  startDsuSession: () => void;
}) =>
  (() => {
    startDsuSession();
  }) as CommandBarCallbackAction;

// Deprecated - kept for backwards compatibility
// This will when replacing all lower tier Calendly links
export const openCalendlyModalCbAction = ({
  openCalendlyModal,
}: {
  openCalendlyModal: ({ url }: { url?: string }) => void;
}) =>
  ((_args, _context, meta) => {
    const props = getProps(meta);
    const { url } = props;
    openCalendlyModal({ url });
  }) as CommandBarCallbackAction;

export const openCalendlyEnterpriseModalCbAction = ({
  openCalendlyEnterpriseModal,
}: {
  openCalendlyEnterpriseModal: ({ url }: { url?: string }) => void;
}) =>
  ((_args, _context, meta) => {
    const props = getProps(meta);
    const { url } = props;
    openCalendlyEnterpriseModal({ url });
  }) as CommandBarCallbackAction;
