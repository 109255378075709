import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { useIsEmbedded } from 'hooks/useIsEmbedded';

import { Mobile } from 'components/MediaQuery';

import { browserHistory, normalizeRoute } from 'util/router';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import { useExtendUx, withUxRoot } from 'util/uxEvents';

import Navigation from './LeftNavigationView';

const LeftNavigationView = ({ store }) => {
  const extendUxEvents = useExtendUx();
  const isEmbedded = useIsEmbedded();

  useEffect(() => {
    const unlisten = browserHistory.listen(location => {
      extendUxEvents({ route: normalizeRoute(location.pathname) });
    });
    return unlisten;
  }, [extendUxEvents]);

  if (isEmbedded) {
    return null;
  }

  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <LastLocationProvider>
          <Mobile>{mobile => <Navigation mobile={mobile} />}</Mobile>
        </LastLocationProvider>
      </Router>
    </Provider>
  );
};

export default withUxRoot({
  productArea: PRODUCT_AREAS.NAVIGATION,
  eventCategory: EVENT_CATEGORIES.LEFT_NAVIGATION,
})(LeftNavigationView);
