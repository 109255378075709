import { trialPeriodsRetrialMarketingRoute, trialPeriodsRoute } from 'api';
import { isEmpty } from 'lodash';

import { showRetrialOnboardingModal } from 'actions/modals';

import { createAsyncPostAction, withAlerts } from 'util/redux';

export const actionTypes = {
  CLOSE_MODAL: 'RETRIAL/CLOSE_MODAL',
  OPEN_MODAL: 'RETRIAL/OPEN_MODAL',
  ACTIVATE_RETRIAL: 'RETRIAL/ACTIVATE_RETRIAL',
  START_TRIAL_REQUEST: 'RETRIAL/START_TRIAL_REQUEST',
  START_TRIAL_SUCCESS: 'RETRIAL/START_TRIAL_SUCCESS',
  START_TRIAL_FAILURE: 'RETRIAL/START_TRIAL_FAILURE',
  SEND_RETRIAL_MARKETING_REQUEST: 'RETRIAL/SEND_RETRIAL_MARKETING_REQUEST',
  SEND_RETRIAL_MARKETING_SUCCESS: 'RETRIAL/SEND_RETRIAL_MARKETING_SUCCESS',
  SEND_RETRIAL_MARKETING_FAILURE: 'RETRIAL/SEND_RETRIAL_MARKETING_FAILURE',
};

export const closeModal = () => ({ type: actionTypes.CLOSE_MODAL });
export const openModal = () => ({ type: actionTypes.OPEN_MODAL });
export const activateRetrial = text => ({
  type: actionTypes.ACTIVATE_RETRIAL,
  payload: { text, showToolTip: true },
});

export const sendRetrialMarketing = () =>
  createAsyncPostAction(trialPeriodsRetrialMarketingRoute(), [
    actionTypes.SEND_RETRIAL_MARKETING_REQUEST,
    actionTypes.SEND_RETRIAL_MARKETING_SUCCESS,
    actionTypes.SEND_RETRIAL_MARKETING_FAILURE,
  ]);

export const startAioTrial =
  ({ payload, onSuccess }) =>
  dispatch =>
    dispatch(
      withAlerts(
        createAsyncPostAction(
          trialPeriodsRoute(),
          [
            actionTypes.START_TRIAL_REQUEST,
            actionTypes.START_TRIAL_SUCCESS,
            actionTypes.START_TRIAL_FAILURE,
          ],
          {
            body: { ...payload },
          }
        ),
        {
          onSuccess: () => {
            onSuccess();
          },
        }
      )
    );

export const startTrial =
  ({ payload, onSuccess }) =>
  dispatch =>
    dispatch(
      withAlerts(
        createAsyncPostAction(
          trialPeriodsRoute(),
          [
            actionTypes.START_TRIAL_REQUEST,
            actionTypes.START_TRIAL_SUCCESS,
            actionTypes.START_TRIAL_FAILURE,
          ],
          {
            body: { ...payload },
          }
        ),
        {
          onSuccess: response => {
            if (!isEmpty(response.payload.retrialOnboardingSteps)) {
              dispatch(closeModal());
              dispatch(
                showRetrialOnboardingModal(
                  response.payload.retrialOnboardingSteps
                )
              );
            } else {
              dispatch(activateRetrial(response.payload.daysLeftInTrial));
            }
            if (onSuccess) {
              onSuccess();
            }
          },
        }
      )
    );
