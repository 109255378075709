import { useCallback, useEffect, useRef, useState } from 'react';
import noop from 'lodash/noop';

import { moment } from 'util/dateTime';

const startOfNextYear = moment().startOf('year').add(1, 'year');

export const useCountDownTimer = ({
  hideTimer = false,
  onHideTimer = noop,
  endDate = startOfNextYear,
} = {}) => {
  const intervalId = useRef();
  const deadLine = moment(endDate);

  const calculateTimeLeft = useCallback(() => {
    const timeNow = moment();

    const days = deadLine.diff(timeNow, 'days');
    timeNow.add(days, 'days');

    const hours = deadLine.diff(timeNow, 'hours');
    timeNow.add(hours, 'hours');

    const minutes = deadLine.diff(timeNow, 'minutes');
    timeNow.add(minutes, 'minutes');

    const seconds = deadLine.diff(timeNow, 'seconds');
    timeNow.add(seconds, 'seconds');

    return { days, hours, minutes, seconds };
  }, [deadLine]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (hideTimer) {
        clearInterval(intervalId.current);
        onHideTimer();
        return;
      }

      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => intervalId.current && clearInterval(intervalId.current);
  }, [calculateTimeLeft, deadLine, hideTimer, onHideTimer]);

  if (!hideTimer) return timeLeft;
};
