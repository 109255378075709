import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  openPayrollFailedPaymentReattemptWithdrawalModal,
  openPayrollFailedPaymentResolutionModal,
  openPayrollFailedPaymentWireFundsModal,
  showPayrollContactModal,
} from 'actions/modals';

import { getCurrentCompanyPayrollPaymentAttemptsInfo } from 'selectors/session';

const BUTTON_TEXT = {
  resolve: 'resolve',
  resolution_pending: 'resolution_pending',
  contact_us: 'contact_us',
};

const RESOLUTIONS = {
  wire_funds: 'wire_funds',
  reattempt_withdrawal: 'reattempt_withdrawal',
};

export const useHandleFailedPaymentModal = () => {
  const dispatch = useDispatch();
  const payrollPaymentAttemptsInfo =
    useSelector(getCurrentCompanyPayrollPaymentAttemptsInfo)?.toJS() || {};

  return useCallback(() => {
    const buttonText = payrollPaymentAttemptsInfo.button_text;

    if (buttonText === BUTTON_TEXT.resolve) {
      dispatch(openPayrollFailedPaymentResolutionModal());
    } else if (buttonText === BUTTON_TEXT.resolution_pending) {
      const resolution = payrollPaymentAttemptsInfo.resolution;

      if (resolution === RESOLUTIONS.wire_funds) {
        dispatch(
          openPayrollFailedPaymentWireFundsModal({
            amount: payrollPaymentAttemptsInfo.amount,
          })
        );
      } else if (resolution === RESOLUTIONS.reattempt_withdrawal) {
        dispatch(openPayrollFailedPaymentReattemptWithdrawalModal());
      }
    } else if (buttonText === BUTTON_TEXT.contact_us) {
      dispatch(showPayrollContactModal());
    }
  }, [payrollPaymentAttemptsInfo, dispatch]);
};
