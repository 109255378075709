import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import queryString from 'query-string';

/**
 * @param {Object} [options] - contains query string object. E.g { nbk_dialog: true }
 */
export const checkHours = createAsyncThunk('help/checkHours', (options = {}) =>
  fetchJSON(`/check_hours.json?${queryString.stringify(options)}`)
);
